import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';


//清除浏览器默认样式
import '../node_modules/normalize.css/normalize.css'

//自适应rem
import '../node_modules/amfe-flexible/index.js'

//fastclick
import FastClick from 'fastclick';
FastClick.attach(document.body);

import '../static/css/reset.css';

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)
// vant全局按需引入
import { Tab, Tabs,Field ,CellGroup,DatetimePicker,ActionSheet,Picker,Dialog,Popup,Toast,CheckboxGroup,Checkbox} from 'vant';

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(CellGroup );
Vue.use(Field);
Vue.use(DatetimePicker );
Vue.use(ActionSheet );
Vue.use(Picker );
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')
