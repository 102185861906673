import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("../view/login"),
        meta: {
            title: '登录',
            keepAlive: true,
        }
    },
    {
        path: '/index',
        name: 'index',
        component: () => import("../view/index"),
        meta: {
            title: '护肝保疾病保险',
            isBack: false,
            keepAlive: true,
        }
    },
    {
        path: '/rightsAndInterests',
        name: 'rightsAndInterests',
        component: () => import("../view/rightsAndInterests"),
        meta: {
            title: '保障权益',
        }
    },
    {
        path: '/insurePackage',
        name: 'insurePackage',
        component: () => import("../view/insurePackage/pages/insure/index"),
        meta: {
            title: '投保流程',
            isBack: false,
            keepAlive: true,
        }
    },
    {
        path: '/confirm',
        name: 'confirm',
        component: () => import("../view/insurePackage/pages/insure/confirmOrder"),
        meta: {
            title: '收银台',
        }
    },
    {
        path: '/material',
        name: 'material',
        component: () => import("../view/material"),
        meta: {
            title: '产品资料',
        }
    },
    {
        path: '/fileList',
        name: 'fileList',
        component: () => import("../view/fileList"),
        meta: {
            title: '产品资料',
        }
    },
    {
        path: '/pdf',
        name: 'pdf',
        component: () => import("../view/pdf"),
        meta: {
            title: '查看',
        }
    },
    {
        path: '/paySuccess',
        name: 'success',
        component: () => import("../view/paySuccess"),
        meta: {
            title: '支付成功',
        }
    },
    {
        path: '/payError',
        name: 'error',
        component: () => import("../view/payError"),
        meta: {
            title: '支付失败',
        }
    },
    {
        path: '/orderList',
        name: 'orderList',
        component: () => import("../view/orderList"),
        meta: {
            title: '我的订单',
        }
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import("../view/orderDetail"),
        meta: {
            title: '订单详情',
        }
    },
    {
        path: '/orderLogin',
        name: 'orderLogin',
        component: () => import("../view/orderLogin"),
        meta: {
            title: '登录',
            keepAlive: true,
            isBack: false,
        }
    }
]
const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, saveTop) {
        if (saveTop) { 
            return saveTop; 
        } else { 
            return { x: 0, y: 0 } 
        } 
    },
    base: process.env.BASE_URL,
    routes
})

export default router