<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { record } from "rrweb";
import wx from "./wx-jssdk";
// import wx from 'weixin-js-sdk'
import { getRecordId, sectionUpload, getJsapiTicket } from "./utils/api";
var timer = null;
export default {
  name: "App",
  data() {
    return {
      recordId: "", //回溯id
      content: [], //回溯内容
    };
  },
  async created() {
    if (sessionStorage.getItem("orders")) {
      sessionStorage.setItem("orders", sessionStorage.getItem("orders"));
    } else {
      sessionStorage.setItem("orders", 1);
    }

    if (sessionStorage.getItem("recordId")) {
      this.recordId = sessionStorage.getItem("recordId");
    } else {
      await this.getRecordId();
    }

    await this.getStart();
    await this.sectionUpload(1);
  },
  mounted() {
    this.getJsapiTicket();
  },
  beforeMount() {
    console.log(timer);
    let self = this;
    timer = setInterval(async function () {
      await self.sectionUpload(1);
    }, 3 * 1000);
  },
  methods: {
    //获取定位jsapi
    async getJsapiTicket() {
      // let url = encodeURIComponent(window.location.href.split("#")[0]);
      let params = {
        channelId: "wx20508d66c240b36a",
        url: window.location.href,
      };
      await getJsapiTicket(params).then((res) => {
        // this.jsapiTicket = res.result;
        wx.config({
          debug: false,
          appId: res.result.appId,
          timestamp: res.result.timestamp,
          nonceStr: res.result.nonceStr,
          signature: res.result.signature,
          jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline",'hideMenuItems'],
        });
        wx.ready(function () {
          wx.hideMenuItems({
            menuList: [
              "menuItem:copyUrl",
            ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
          });
          // 分享好友
          wx.onMenuShareAppMessage({
            title: "肝护保疾病保险", // 分享标题
            desc: "乙肝病毒携带者及慢性乙肝患者可适用", // 分享描述
            link: "https://ins.xm.lejian.net?id=1450714352756387842", // 分享链接
            imgUrl:
              "https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/131922.png", // 分享图标
            type: "link", // 分享类型,music、video或link，不填默认为link
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            },
          });
          // 分享朋友圈
          wx.onMenuShareTimeline({
            title: '肝护保疾病保险', // 分享标题
            link: 'https://ins.xm.lejian.net?id=1450714352756387842', // 分享链接
            imgUrl: 'https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/131922.png', // 分享图标
            success: function () { 
                // 用户确认分享后执行的回调函数
            },
            cancel: function () { 
                // 用户取消分享后执行的回调函数
            }
        });
        });
      });
    },

    //分段上传回溯记录
    async sectionUpload(type) {
      if (this.content.length != 0) {
        let orders = sessionStorage.getItem("orders");
        let newOrders = parseInt(orders) + 1;
        sessionStorage.setItem("orders", newOrders);
        let params = {
          content: this.content,
          recordId: this.recordId,
          status: type,
          orders: newOrders,
        };
        this.content = [];
        await sectionUpload(params).then((res) => {
          console.log(res);
        });
      }
    },
    //获取回溯id
    async getRecordId() {
      await getRecordId().then((res) => {
        this.recordId = res.result;
        sessionStorage.setItem("recordId", res.result);
      });
    },
    //开始录制
    async getStart() {
      let self = this;
      await record({
        emit(event) {
          // 用任意方式存储 event
          self.content.push(event);
        },
      });
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
