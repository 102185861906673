// import Vue from 'vue'
import axios from 'axios'
// import { from } from 'core-js/fn/array'
// import router from '../router/index';
import {Toast} from 'vant'
// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers = {
        'Content-Type': 'application/json'
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

// http response 拦截器
axios.interceptors.response.use(
  response => {
    
    if(response.data.success){
      return response.data
      
    }else{
      setTimeout(function(){
        Toast(response.data.message)
      },1000)
      
    }
  },
  error => {
    Toast.clear();
    return Promise.reject(error)
  })


//get请求
export const GET = (url, param) => {
  if (param) {
    return axios.get(url, {
      params: param
    })
  } else {
    return axios.get(url)
  }
}
//post请求
export const POST = (url, param, config) => {
  if (config) {
    return axios.post(url, param, config)
  } else {
    return axios.post(url, param)
  }
}

export const PUT = (url, param) => {
  return axios.put(url, param)
}

export const DELETE = (url) => {
  return axios.delete(url)
}

