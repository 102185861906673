import { GET,POST } from '../utils/http'
import config from '../../constans';
let url = `${config.baseUrl.apiUrl}/insurance/api`

// 产品详情
export const productDetail = (params) => {
  return GET(`${url}/product/productDetailNoLogin`,params)
}

// 权益详情
export const productBenefitDetail = (params) => {
  return GET(`${url}/product/productBenefitDetail`,params)
}

// 获取回溯id
export const getRecordId = () => {
  return GET(`${url}/opRecord/getRecordId`)
}

// 分段上传录屏
export const sectionUpload = (params) => {
  return POST(`${url}/opRecord/sectionUpload`,params)
}


// 地区信息
export const postSelectByArea = () => {
  return POST(`${url}/area/selectByArea`)
}

// 职业信息
export const getSelectOccupationList = () => {
  return POST(`${url}/occupation/selectOccupationList`)
}

// // 身份证校验
// export const esignComparison =(params)=> {
//   return POST(`${url}/esign/comparison`,params)
// }

// 投保接口
// export const underwrite =(params)=> {
//   return POST(`${url}/insure/underwrite`,params)
// }

// 查找产品下的险种信息（主+附）

export const selectRisk =(params)=> {
  return POST(`${url}/insure/selectRisk?productId=${params.productId}`,params)
}

//获取产品费率表
export const getRateTableFileByProductId = (params) => {
  return GET(`${url}/product/getRateTableFileByProductId`,params)
}
//获取产品条款
export const getProductAttachment = (params) => {
  return GET(`${url}/product/getProductAttachment`,params)
}

//下载pdf
export const download = `${url}/file/getProductFile/`

//获取订单详情
// export const getOrderDetail = (params) => {
//   return GET(`${url}/order/one`,params)
// }

// //获取openId
// export const getOpenId = (params) => {
//   return GET(`${url}/login/getOpenId`,params)
// }


//信美--------------------------------
//信美试算接口
export const xmReckon = (params) => {
  return GET(`${url}/xm/reckon`,params)
}

//信美投保接口
export const xmInsured = (params) => {
  return POST(`${url}/xm/insured`,params)
}

//获取openId
export const getOpenId = (params) => {
  return GET(`${url}/login/getOpenId`, params)
}

//收银台支付
export const payOrderWxPub = (params) => {
  return GET(`${url}/pay/xmPayOrderWxPub`, params)
}

//信美出保单接口
export const xmIssue = (params) => {
  return POST(`${url}/xm/issue`,params)
}

// 身份证姓名校验
export const esignComparison =(params)=> {
  return POST(`${url}/esign/comparison`,params)
}

//发送验证码
export const getCode = (params) => {
  return GET(`${url}/msn/sms`, params)
}

//校验验证码
export const checkCode = (phone,params) => {
  return GET(`${url}/apply/checkCode/${phone}`, params)
}

//获取微信api
export const getJsapiTicket = (params) => {
  return GET(`${url}/wx/getJsapiTicket`, params)
}

//订单列表
export const getOrderList = (params) => {
  return GET(`${url}/xm/order/all`, params)
}

//订单详情
export const getOrderDetail = (params) => {
  return GET(`${url}/xm/order/details`, params)
}

//取消订单
export const cancelOrder = (params) => {
  return GET(`${url}/xm/revoke`, params)
}







